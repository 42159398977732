.burgerMenuCont {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.openedBurger {
    position: absolute;
    top: 18px;
    left: 70px;
    background-color: #535353;
    padding: 8px;
    border: 2px solid #cbcbcb;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0px 4px 10px 10px #535353;
    z-index: 5;
    animation: openBurgerMenu 0.25s;
}

.activeBurgerLink {
    font-style: italic;
    color: #7cc1fc;
    font-size: 24px;
    text-decoration: underline;
}

.activeBurgerLink:focus {
    text-decoration: underline;
}

.unactiveBurgerLink {
    color: white;
    font-size: 24px;
}

.closeMenu {
    animation: closeBurgerMenu 0.25s;
}

@keyframes openBurgerMenu {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes closeBurgerMenu {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
