.activeLink {
    color: #7cc1fc;
    font-size: 18px;
    text-decoration: underline;
    font-style: italic;
}

.activeLink:focus {
    text-decoration: underline;
}

.unactiveLink {
    color: white;
    font-size: 18px;
    text-decoration: none;
}

.unactiveLink:hover {
    color: #58b3ff;
}
