.appContainer {
    width: 100%;
    min-height: calc(100vh - 104px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.fieldsContainer {
    display: flex;
    gap: 20px;
}

.btnContainer {
    margin-top: 80px;
}

.hideHeader {
    max-height: 0;
    overflow: hidden;
    border: none;
    animation: hideHeaderAnim 0.5s;
}

@keyframes hideHeaderAnim {
    0% {
        max-height: var(--navPanelHeight);
    }
    100% {
        max-height: 0;
        padding: 0;
    }
}

@media screen and (max-width: 1215px) {
    .appContainer {
        flex-direction: column;
    }
}

@media screen and (max-width: 600px){
    .appContainer {
        padding-top: 4px;
    }
}
