.logoutBtn {
    margin-left: 8px;
}

.profileLink {
    font-size: 18px;
    text-decoration: none;
}
.profileLink:visited {
    color: #48a5ff;
}

.profileLink:hover {
    color: #2093ff;
}